import { Faq } from "@/models";
import { search } from "@/shareds/search-utils";
import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "..";

@Module({
	name: 'FaqStore',
	namespaced: true,
	dynamic: true,
	store,
})
export default class FaqStore extends VuexModule{
	busca = ''
	faqs: Faq[] = []
	faqSelecionada: Faq | null = null



	@Mutation
	adicionarFaq(faq: Faq) {
		this.faqs.push(faq)
	}

	@Mutation
	atualizarFaq(faq: Faq) {
		const userIndex = this.faqs.findIndex(
			element => element.id === faq.id,
		)
		this.faqs.splice(userIndex, 1, faq)
	}

	@Mutation
	setFaqs(faqs: Faq[]) {
		this.busca = ''
		this.faqs = faqs
	}

	@Mutation
	setFaqSelecionada(faq: Faq) {
		this.faqSelecionada = faq
	}

	get faqsFiltradas() {
		if (!this.busca) return this.faqs
		const filtered = search (this.faqs , this.busca)
		return filtered as Faq[]
	}
	
	get faqsOrdenadas() {
		return [...this.faqsFiltradas].sort(ordenarFaqsPorNome)
	}


}export const FaqModule = getModule(FaqStore)

export function ordenarFaqsPorNome(faqA: Faq, faqB: Faq) {
	return faqA.titulo.localeCompare(faqB.titulo)
}